.App-logo{
  padding: 1em 1em 0 1em;
  height: 5em;
  filter: invert(100%);
}

.toolName{
  text-align: center;
  border-bottom: 1px solid;
  margin: 0 1em;

}